import { Paper, Grid, Typography, Box, useTheme } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { endPoint } from "../../api/axios";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";

const ResetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const axios = useAxios();

  useEffect(() => {
    const processRequest = async () => {
      try {
        let obj = {
          token: token,
          origin: "",
        };
        const response = await axios.put(
          endPoint.AccountInfo + "/ResetPassword",
          obj,
          {
            headers: {
              "Content-Type": "application/json;",
            },
          }
        );
        if (response.status === 200) setError("");
      } catch (err) {
        console.log(err);
        setError(err.response?.data);
      } finally {
        setLoading(false);
      }
    };
    processRequest();
  }, [token, axios]);

  const paperStyle = {
    padding: 20,
    height: "40vh",
    width: 500,
    margin: "auto",
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      {isLoading ? (
        <Grid align="center">
          <Box>
            <CircularProgress />
            <Typography variant="h5">Reset Password Processing</Typography>
          </Box>
        </Grid>
      ) : (
        <Grid align="center">
          {error.length < 1 ? (
            <Box>
              <CheckCircleOutlinedIcon
                sx={{ fontSize: 100, color: colors.greenAccent[400] }}
              />
              <Typography variant="h5">Password Reset Successful</Typography>
              <Typography>
                Proceed to Login ? <Link to="/login"> Login </Link>
              </Typography>
            </Box>
          ) : (
            <>
              <CancelOutlinedIcon
                sx={{ fontSize: 100, color: colors.redAccent[400] }}
              />
              <Typography variant="h5">Password Reset Failed.</Typography>
              <Typography variant="p" color={colors.redAccent[500]}>
                {error}
              </Typography>
              <Typography>
                Proceed to Login ? <Link to="/login"> Login </Link>
              </Typography>
            </>
          )}
        </Grid>
      )}
    </Paper>
  );
};

export default ResetPassword;
