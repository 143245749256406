import Header from "../../components/Header";
import { Box, Button, TextField, Tabs, Tab, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TabPanel from "../../components/TabPanel";

import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import { Formik } from "formik";
import format from "date-fns/format";
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";

const phoneRegex =
  /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/; // Regex ĐT VN
const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const profileSchema = yup.object().shape({
  fullName: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Invalid Phone Number")
    .notRequired(),
  birthdate: yup.date().notRequired(),
});

const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .matches(
      passRegex,
      "Password must be 8 to 24 characters long, have at least one lower case, one upper case, one digit and one special character (!@#$%)"
    )
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "New Password and Confirm Password must match"
    ),
});

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    birthdate: "",
  });
  const axios = useAxios();

  const fetchData = useCallback(async () => {
    const controller = new AbortController();

    try {
      let url = endPoint.AccountInfo + "/Profile";
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setProfile(response?.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeValue = (e, value) => {
    setValue(value);
  };

  const handleEditProfile = async (values) => {
    let obj = {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate
        ? format(new Date(values.birthdate), "dd/MM/yyyy")
        : "",
    };
    try {
      const response = await axios.put(endPoint.AccountInfo + "/Profile", obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const handleChangePassword = async (values) => {
    let obj = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    try {
      const response = await axios.put(
        endPoint.AccountInfo + "/ChangePassword",
        obj,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tab-panel-${index}`,
    };
  };

  return (
    <Box m="20px">
      <Header title="PROFILE" subTitle="Edit Profile" />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeValue}
          aria-label="Profile Tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Edit Profile" {...a11yProps(0)} />
          <Tab label="Change Password" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {loading || profile.fullName.length < 1 ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            enableReinitialize
            onSubmit={handleEditProfile}
            initialValues={profile}
            validationSchema={profileSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(2, minmax(0,1fr))"
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Full Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fullName ?? ""}
                    name="fullName"
                    error={!!errors.fullName}
                    helperText={errors.fullName}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email ?? ""}
                    name="email"
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNumber ?? ""}
                    name="phoneNumber"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="filled"
                    type="date"
                    label="Date of Birth"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={
                      values.birthdate
                        ? format(new Date(values.birthdate), "yyyy-MM-dd")
                        : ""
                    }
                    name="birthdate"
                    error={!!errors.birthdate}
                    helperText={errors.birthdate}
                    sx={{ gridColumn: "span 1" }}
                  />
                </Box>
                <Box display="flex" justifyContent="start" mt="20px">
                  <Button
                    type="submit"
                    sx={{
                      color: colors.grey[100],
                      backgroundColor: colors.greenAccent[600],
                      ":hover": {
                        backgroundColor: colors.greenAccent[700],
                      },
                    }}
                    variant="outlined"
                  >
                    Save
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Formik
          onSubmit={handleChangePassword}
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={passwordSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(3, minmax(0,1fr))"
              >
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  type="password"
                  label="Current Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.currentPassword}
                  name="currentPassword"
                  error={!!errors.currentPassword}
                  helperText={errors.currentPassword}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  type="password"
                  label="New Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                  name="newPassword"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  type="password"
                  label="Confirm Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              <Box display="flex" justifyContent="start" mt="20px">
                <Button
                  type="submit"
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.greenAccent[600],
                    ":hover": {
                      backgroundColor: colors.greenAccent[700],
                    },
                  }}
                  variant="outlined"
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </TabPanel>
    </Box>
  );
};

export default Profile;
