import { enqueueSnackbar } from "notistack";

const showMessage = (message, variant) => {
  enqueueSnackbar(message, {
    variant: variant,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    autoHideDuration: 5000,
  });
};

const buildUrlParams = (params) => {
  return "?" + new URLSearchParams(params).toString();
};

const search = (items, searchParam, query) => {
  return items.filter((item) => {
    return searchParam.some((newItem) => {
      return (
        item[newItem].toString().toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    });
  });
};

const checkRegex = (text, regex) => {
  return regex.test(text);
};

const checkContains = (array, value) => {
  if (!value) return true;
  return array?.some((val) => {
    if (val.trim() === value.trim()) return true;
    return false;
  });
};

const utils = {
  showMessage: showMessage,
  buildUrlParams: buildUrlParams,
  search: search,
  checkRegex: checkRegex,
  checkContains: checkContains,
};

export default utils;
