import { Outlet } from "react-router-dom";
import { CssBaseline, Grid, Box } from "@mui/material";

const LoginLayout = () => {
  return (
    <main className="app">
      <div className="content">
        <Box className="login-background">
          <Grid sx={{ paddingTop: "10vh" }}>
            <CssBaseline />
            <Outlet />
          </Grid>
        </Box>
      </div>
    </main>
  );
};

export default LoginLayout;
