import { createSlice } from "@reduxjs/toolkit";

export const confirmSlice = createSlice({
  name: "confirm",
  initialState: {
    open: false,
    dialogTitle: "Title",
    dialogContent: "Content",
    onSubmit: undefined,
  },
  reducers: {
    closeDialog: (state) => {
      state.open = false;
      state.onSubmit = undefined;
    },
    openDialog: (state, action) => {
      const { title, content, onSubmit } = action.payload;
      state.dialogTitle = title;
      state.dialogContent = content;
      state.onSubmit = onSubmit;
      state.open = true;
    },
  },
});

export const { openDialog, closeDialog } = confirmSlice.actions;
export default confirmSlice.reducer;
