import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Header from "../../components/Header";
import AssignSystems from "./assignSystems";
import AssignDefaultRole from "./assignDefaultRole";
import SmtpSetting from "../smtpSetting";

import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";
import { useNavigate } from "react-router-dom";
import Restricted, { useRestricted } from "../../components/Restricted";
import permission from "../../permission";
// import format from "date-fns/format";

const Client = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxios();
  const isEdit = useRestricted(permission.update.client);

  const [assignSys, setAssignSys] = useState({
    clientId: "",
    clientName: "",
    systemIds: [],
    open: false,
  });

  const [defaultRole, setDefaultRole] = useState({
    clientId: "",
    clientName: "",
    roles: [],
    open: false,
  });

  const [smtpSetting, setSmtpSetting] = useState({
    clientId: "",
    clientName: "",
    smtpSetting: {},
    open: false,
  });

  const columns = [
    {
      field: "clientCode",
      headerName: "Client Code *",
      editable: isEdit,
      minWidth: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "clientName",
      headerName: "Client Name *",
      editable: isEdit,
      minWidth: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "urlViewer",
      headerName: "Url Viewer",
      editable: isEdit,
      minWidth: 200,
    },
    {
      field: "urlPacs",
      headerName: "Url Pacs",
      editable: isEdit,
      minWidth: 200,
    },
    {
      field: "urlPacsApi",
      headerName: "Url Pacs Api",
      editable: isEdit,
      minWidth: 200,
    },
    {
      field: "manage",
      headerName: "Manage",
      editable: false,
      minWidth: 500,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Restricted to={permission.create.systemsClient}>
              <Button
                variant="outlined"
                startIcon={<AssignmentOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.greenAccent[500],
                  ":hover": {
                    backgroundColor: colors.greenAccent[600],
                  },
                }}
                onClick={() => handleAssignSystem(params.row)}
                disabled={params.row.isNew ? true : false}
                size="small"
              >
                Systems
              </Button>
            </Restricted>
            <Restricted to={permission.list.role}>
              <Button
                variant="outlined"
                startIcon={<VisibilityOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[500],
                  ":hover": {
                    backgroundColor: colors.blueAccent[600],
                  },
                }}
                onClick={() =>
                  navigate(`/role/${params.id}/${params.row.clientName}`)
                }
                disabled={params.row.isNew ? true : false}
                size="small"
              >
                Roles
              </Button>
            </Restricted>
            <Restricted to={permission.update.defaultRole}>
              <Button
                variant="outlined"
                startIcon={<SettingsOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.redAccent[500],
                  ":hover": {
                    backgroundColor: colors.redAccent[600],
                  },
                }}
                onClick={() => handleDefaultRole(params.row)}
                disabled={params.row.isNew ? true : false}
                size="small"
              >
                Default Role
              </Button>
            </Restricted>
            <Restricted to={permission.list.account}>
              <Button
                variant="outlined"
                startIcon={<VisibilityOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.grey[700],
                  ":hover": {
                    backgroundColor: colors.grey[800],
                  },
                }}
                onClick={() =>
                  navigate(`/account/${params.id}/${params.row.clientName}`)
                }
                disabled={params.row.isNew ? true : false}
                size="small"
              >
                Accounts
              </Button>
            </Restricted>
            <Restricted to={permission.update.smtp}>
              <Button
                variant="outlined"
                startIcon={<SettingsOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.greenAccent[500],
                  ":hover": {
                    backgroundColor: colors.greenAccent[600],
                  },
                }}
                onClick={() => handleSmtpSetting(params.row)}
                disabled={params.row.isNew ? true : false}
                size="small"
              >
                Smtp
              </Button>
            </Restricted>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Action",
      minWidth: 50,
      editable: false,
      renderCell: (params) => {
        return (
          <Restricted to={permission.delete.client}>
            <IconButton
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[500],
                ":hover": {
                  backgroundColor: colors.redAccent[600],
                },
              }}
              onClick={() => {
                handleDeleteRow(params.row);
              }}
              disabled={params.row.isNew ? true : false}
              size="small"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Restricted>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();

    try {
      const response = await axios.get(endPoint.ClientInfo, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteRow = async (row) => {
    let clientId = row.clientId;
    dispatch(
      openDialog({
        title: "Delete Client",
        content: `Are you sure you want to Delete Client : "${row.clientName}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.delete(
              endPoint.ClientInfo + `/${clientId}`
            );
            if (response?.status === 200) deleteRow(clientId);
          } catch (err) {
            console.log(err);
            utils.showMessage(
              `${err.message} : ${err.response?.data}`,
              "error"
            );
          }
        },
      })
    );
  };

  const handleNewRow = () => {
    const clientId = Date.now().toString();
    apiRef.current.updateRows([
      {
        clientId,
        clientCode: "",
        clientName: "",
        urlViewer: "",
        urlPacs: "",
        urlPacsApi: "",
        note: "",
        status: false,
        isNew: true,
      },
    ]);

    let sort = apiRef.current
      .getSortedRows()
      .sort((a, b) => a.status - b.status);
    setRowData(sort);

    apiRef.current.startRowEditMode({
      id: clientId,
      fieldToFocus: "clientCode",
    });
  };

  const handleProcessRowUpdateError = (error) => {
    utils.showMessage(`Error ${error}`, "error");
  };

  const handleRowEditStop = (params) => {
    let row = params.row;
    if (params.reason === "escapeKeyDown" && row.isNew) {
      deleteRow(row.clientId);
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.clientCode.trim().length < 1)
      throw new Error("Client Code Must Have Value !");

    if (newRow.clientName.trim().length < 1)
      throw new Error("Client Name Must Have Value !");

    let obj = {
      clientCode: newRow.clientCode,
      clientName: newRow.clientName,
      urlViewer: newRow.urlViewer,
      urlPacs: newRow.urlPacs,
      urlPacsApi: newRow.urlPacsApi,
      note: newRow.note,
    };

    let response;

    if (!newRow?.isNew) {
      obj.clientId = newRow.clientId;
      response = await axios.put(endPoint.ClientInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      return newRow;
    } else {
      response = await axios.post(endPoint.ClientInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        deleteRow(newRow.clientId);
        newRow.clientId = response.data.clientId;
        newRow.isNew = false;
        newRow.status = true;
        return newRow;
      }
    }
  };

  const deleteRow = (clientId) => {
    apiRef.current.updateRows([{ clientId: clientId, _action: "delete" }]);
  };

  const handleAssignSystem = async (row) => {
    let clientId = row.clientId;
    try {
      const response = await axios.get(
        endPoint.SystemsByClient + `/${clientId}`
      );

      if (response?.status === 200)
        setAssignSys({
          clientId: clientId,
          clientName: row.clientName,
          systemIds: response.data,
          open: true,
        });
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const handleDefaultRole = async (row) => {
    try {
      const response = await axios.get(
        endPoint.RolesByClient +
          "/DefaultRole" +
          utils.buildUrlParams({ clientId: row.clientId })
      );
      if (response?.status === 200)
        setDefaultRole({
          clientId: row.clientId,
          clientName: row.clientName,
          roles: response.data,
          open: true,
        });
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const handleSmtpSetting = async (row) => {
    let url = endPoint.ClientInfo + `/SmtpSetting/${row.clientId}`;
    try {
      const response = await axios.get(url);
      if (response?.status === 200)
        setSmtpSetting({
          clientId: row.clientId,
          clientName: row.clientName,
          smtpSetting: response.data,
          open: true,
        });
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  return (
    <Box m="10px">
      <Header title="CLIENT" subTitle="Managing Clients" />
      <Box display="flex">
        <Restricted to={permission.create.client}>
          <Button
            variant="outlined"
            startIcon={<NoteAddOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            onClick={handleNewRow}
          >
            New
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
      </Box>
      <Box
        m="10px 0 0 0 "
        height={"calc(100vh - 35vh)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.clientId}
          editMode="row"
          autoPageSize
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
      <AssignSystems data={assignSys} setData={setAssignSys} />
      <AssignDefaultRole data={defaultRole} setData={setDefaultRole} />
      <SmtpSetting data={smtpSetting} setData={setSmtpSetting} />
    </Box>
  );
};

export default Client;
