import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const toHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "50vh",
      }}
    >
      <Typography variant="h1" sx={{ color: colors.greenAccent[500] }}>
        404
      </Typography>
      <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
        Page Not Found...
      </Typography>
      <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
        The Page You Requested Was Not Found !
      </Typography>
      <Button
        variant="outlined"
        sx={{
          marginTop: 1,
          background: colors.blueAccent[500],
          ":hover": {
            backgroundColor: colors.blueAccent[600],
          },
          color: colors.primary[100],
        }}
        onClick={() => toHome()}
      >
        Back Home
      </Button>
    </Box>
  );
};

export default NotFound;
