import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Header from "../../components/Header";
import AssignRoles from "./assignRoles";
import { GridEditInputCell } from "@mui/x-data-grid";

import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";
import Restricted, { useRestricted } from "../../components/Restricted";
import permission from "../../permission";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PHONE_REGEX =
  /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/; // Regex ĐT VN
const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const Account = () => {
  const { clientId, clientName } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const phoneRef = useRef();
  // const emailRef = useRef();
  // const userRef = useRef();
  const [assignRole, setAssignRole] = useState({
    accountId: "",
    username: "",
    roles: [],
    open: false,
  });
  const dispatch = useDispatch();
  const axios = useAxios();
  const isEdit = useRestricted(permission.update.account);

  const columns = [
    {
      field: "username",
      headerName: "Username *",
      editable: isEdit,
      renderEditCell: (params) => {
        return <GridEditInputCell {...params} disabled={!params.row.isNew} />;
      },
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "password",
      headerName: "Password *",
      editable: isEdit,
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return "******";
      },
      renderEditCell: (params) => {
        return <GridEditInputCell {...params} disabled={!params.row.isNew} />;
      },
    },
    {
      field: "fullName",
      headerName: "Full Name *",
      editable: isEdit,
      flex: 0.5,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      type: "date",
      editable: isEdit,
      flex: 0.4,
      valueGetter: (params) => {
        let birthDate = params.row.birthDate;
        if (birthDate) return new Date(birthDate);
      },
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      editable: isEdit,
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email *",
      editable: isEdit,
      flex: 1,
    },
    {
      field: "manage",
      headerName: "Manage",
      editable: false,
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box>
            <Restricted to={permission.create.roleAccount}>
              <Button
                variant="outlined"
                startIcon={<AssignmentOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.greenAccent[500],
                  ":hover": {
                    backgroundColor: colors.greenAccent[600],
                  },
                }}
                onClick={() => {
                  handleAssignRole(params.row);
                }}
                disabled={params.row?.isNew ? true : false}
              >
                Roles
              </Button>
            </Restricted>
            <Restricted to={permission.update.resetPwd}>
              <Button
                variant="outlined"
                startIcon={<LockResetOutlinedIcon />}
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[500],
                  ":hover": {
                    backgroundColor: colors.blueAccent[600],
                  },
                }}
                onClick={() => {
                  handleResetPassword(params.row);
                }}
                disabled={params.row.isNew ? true : false}
              >
                Reset
              </Button>
            </Restricted>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Action",
      flex: 0.4,
      editable: false,
      renderCell: (params) => {
        return (
          <Restricted to={permission.delete.account}>
            <IconButton
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[500],
                ":hover": {
                  backgroundColor: colors.redAccent[600],
                },
              }}
              onClick={() => {
                handleDeleteRow(params.row);
              }}
              disabled={params.row.isNew ? true : false}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Restricted>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();

    try {
      let params = {
        clientId: clientId,
        // phoneNumber: phoneRef.current.value,
        // email: emailRef.current.value,
        // username: userRef.current.value,
      };
      let url = endPoint.AccountInfo + utils.buildUrlParams(params);
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [clientId, axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   fetchData();
  // };

  const handleDeleteRow = async (row) => {
    let accountId = row.accountId;
    let url = endPoint.AccountInfo + `/${accountId}`;

    dispatch(
      openDialog({
        title: "Delete Account",
        content: `Are you sure you want to Delete Account : "${row.username}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.delete(url);
            if (response?.status === 200) deleteRow(accountId);
          } catch (err) {
            console.log(err);
            utils.showMessage(
              `${err.message} : ${err.response?.data}`,
              "error"
            );
          }
        },
      })
    );
  };

  const handleNewRow = () => {
    const accountId = Date.now().toString();
    apiRef.current.updateRows([
      {
        clientId: clientId,
        accountId: accountId,
        username: "",
        password: "",
        fullName: "",
        birthDate: "",
        phoneNumber: "",
        email: "",
        status: false,
        isNew: true,
      },
    ]);

    let sort = apiRef.current
      .getSortedRows()
      .sort((a, b) => a.status - b.status);
    setRowData(sort);

    apiRef.current.startRowEditMode({
      id: accountId,
      fieldToFocus: "username",
    });
  };

  const handleProcessRowUpdateError = (error) => {
    if (error.response) utils.showMessage(error.response.data, "error");
    else utils.showMessage(`${error}`, "error");
  };

  const handleRowEditStop = (params) => {
    let row = params.row;
    if (params.reason === "escapeKeyDown" && row.isNew) {
      deleteRow(row.accountId);
    }
  };

  const handleResetPassword = async (row) => {
    let obj = {
      accountId: row.accountId,
      origin: "",
    };
    dispatch(
      openDialog({
        title: "Reset Password Account",
        content: `Are you sure you want to Reset Password for Account : "${row.username}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.post(
              endPoint.AccountInfo + "/ResetPassword/",
              obj,
              {
                headers: {
                  "Content-Type": "application/json;",
                },
              }
            );
            if (response.status === 200)
              utils.showMessage(
                "Send Email Reset Password Succeess !",
                "success"
              );
          } catch (err) {
            console.log(err);
            utils.showMessage(
              `${err.message} : ${err.response?.data}`,
              "error"
            );
          }
        },
      })
    );
  };

  const handleAssignRole = async (row) => {
    try {
      const response = await axios.get(
        endPoint.AccountInfo + `/AccountRoles/${row.accountId}`
      );

      if (response?.status === 200)
        setAssignRole((prev) => ({
          ...prev,
          accountId: row.accountId,
          username: row.username,
          roles: response.data,
          open: true,
        }));
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const processRowUpdate = async (newRow) => {
    let error = validateAccountData(newRow);
    if (error.length > 0) throw new Error(error);

    let obj = {
      clientId: clientId,
      username: newRow.username,
      fullName: newRow.fullName,
      birthDate: newRow.birthDate
        ? format(newRow.birthDate, "dd/MM/yyyy")
        : null,
      phonenumber: newRow.phoneNumber,
      email: newRow.email,
    };

    let response;
    if (!newRow?.isNew) {
      obj.accountId = newRow.accountId;
      response = await axios.put(endPoint.AccountInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      return newRow;
    } else {
      obj.password = newRow.password;
      response = await axios.post(endPoint.AccountInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        deleteRow(newRow.accountId);
        newRow.accountId = response.data.accountId;
        newRow.isNew = false;
        newRow.status = true;

        return newRow;
      }
    }
  };

  const deleteRow = (accountId) => {
    apiRef.current.updateRows([{ accountId: accountId, _action: "delete" }]);
  };

  const validateAccountData = (data) => {
    if (data.username?.trim().length < 1) return "Username must have value !";
    if (
      data.username?.trim().length > 0 &&
      !utils.checkRegex(data.username, USER_REGEX)
    )
      return "Username must be 4 to 24 characters long, start with Lower or Upper case character, can contain digit and underscore !";

    if (data.Fullname?.trim().length < 1) return "Full Name must have value !";

    if (data.isNew && data.password?.trim().length < 1)
      return "Password must have value !";
    if (
      data.password?.trim().length > 0 &&
      !utils.checkRegex(data.password, PWD_REGEX)
    )
      return "Password must be 8 to 24 characters long, have at least one lower case, one upper case, one digit and one special character (!@#$%) !";
    if (data.email?.trim().length < 1) return "Email must have value !";
    if (
      data.phoneNumber?.trim().length > 0 &&
      !utils.checkRegex(data.phoneNumber, PHONE_REGEX)
    )
      return "Invalid Phone Number !";

    if (
      data.email?.trim().length > 0 &&
      !utils.checkRegex(data.email, EMAIL_REGEX)
    )
      return "Invalid Email Format !";

    return "";
  };

  return (
    <Box m="10px">
      <Header
        title="ACCOUNTS"
        subTitle={`Managing Accounts For Client : ${clientName}`}
      />
      <Box display="flex">
        <Restricted to={permission.create.account}>
          <Button
            variant="outlined"
            startIcon={<NoteAddOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            onClick={handleNewRow}
          >
            New
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[500],
            ":hover": {
              backgroundColor: colors.redAccent[600],
            },
          }}
          onClick={() => navigate("/client")}
        >
          Back
        </Button>
        {/* <Box
          onSubmit={handleSubmit}
          component="form"
          ml={2}
          sx={{
            "& label.Mui-focused": {
              color: `${colors.primary[100]}`,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: `${colors.primary[100]}`,
              },
              "&:hover fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
              "&.Mui-focused fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
            },
          }}
        >
          <TextField
            label="Search"
            inputRef={phoneRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            variant="outlined"
            placeholder="Phone Number"
            autoComplete="off"
          />
          <TextField
            sx={{ marginLeft: 1 }}
            label="Search"
            inputRef={emailRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            variant="outlined"
            placeholder="Email"
            autoComplete="off"
          />
          <TextField
            sx={{ marginLeft: 1 }}
            label="Search"
            inputRef={userRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            variant="outlined"
            placeholder="Username"
            autoComplete="off"
          />
          <Button type="submit" sx={{ display: "none" }} />
        </Box> */}
      </Box>
      <Box
        m="10px 0 0 0 "
        height={"calc(100vh - 225px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.accountId}
          editMode="row"
          autoPageSize
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
      <AssignRoles data={assignRole} setData={setAssignRole} />
    </Box>
  );
};

export default Account;
