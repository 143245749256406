import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import Header from "../../components/Header";
import { GridEditInputCell } from "@mui/x-data-grid";

import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";
import { useNavigate } from "react-router-dom";
import Restricted, { useRestricted } from "../../components/Restricted";
import permission from "../../permission";
import format from "date-fns/format";

const Token = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const axios = useAxios();
  const isEdit = useRestricted(permission.update.token);

  const columns = [
    {
      field: "systemName",
      headerName: "System Name *",
      editable: false,
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "accessTokenExpire",
      headerName: "Access Token TTL (Second)",
      type: "number",
      editable: isEdit,
      flex: 1,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 0,
          }}
        />
      ),
    },
    {
      field: "refreshTokenExpire",
      headerName: "Refresh Token TTL (Second)",
      type: "number",
      editable: isEdit,
      flex: 1,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 0,
          }}
        />
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        let date = params.row.updatedDate;
        if (date) return format(new Date(date), "dd/MM/yyyy");
      },
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      editable: false,
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      const response = await axios.get(endPoint.TokenSetting, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleProcessRowUpdateError = (error) => {
    utils.showMessage(`Error ${error}`, "error");
  };

  const processRowUpdate = async (newRow) => {
    let obj = {
      systemId: newRow.systemId,
      accessTokenExpire: newRow.accessTokenExpire,
      refreshTokenExpire: newRow.refreshTokenExpire,
    };

    await axios.put(endPoint.TokenSetting, obj, {
      headers: {
        "Content-Type": "application/json;",
      },
    });
    return newRow;
  };

  const ResetJwt = async () => {
    let url = endPoint.TokenSetting + "/ResetJwt";
    try {
      const response = await axios.put(url, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      if (response.status === 200)
        utils.showMessage("Reset Jwt Success", "success");
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  return (
    <Box m="10px">
      <Header
        title="TOKEN SETTING & SESSION"
        subTitle="Managing Token and Sessions for Systems"
      />
      <Box>
        <Restricted to={permission.list.accountSession}>
          <Button
            variant="outlined"
            startIcon={<VisibilityOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[500],
              ":hover": {
                backgroundColor: colors.greenAccent[600],
              },
            }}
            onClick={() => navigate(`/session/`)}
          >
            Account Session
          </Button>
        </Restricted>
        <Restricted to={permission.update.jwt}>
          <Button
            variant="outlined"
            startIcon={<LockResetOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.redAccent[500],
              ":hover": {
                backgroundColor: colors.redAccent[600],
              },
            }}
            onClick={() => ResetJwt()}
          >
            Reset Jwt
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
      </Box>
      <Box
        m="10px 0 0 0 "
        height={"calc(100vh - 220px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.systemId}
          editMode="row"
          autoPageSize
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
    </Box>
  );
};

export default Token;
