import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import { tokens } from "../theme";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../redux/confirm";

const ConfirmDialog = () => {
  const dialog = useSelector((state) => state.confirmDialog);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = () => {
    dialog.onSubmit();
    dispatch(closeDialog());
  };

  return (
    <Dialog
      open={dialog.open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle variant="h3">{dialog.dialogTitle}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => dispatch(closeDialog())}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="h5">{dialog.dialogContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          startIcon={<CheckOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.greenAccent[600],
            ":hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
          variant="outlined"
        >
          Confirm
        </Button>
        <Button
          onClick={() => dispatch(closeDialog())}
          startIcon={<CloseOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[600],
            ":hover": {
              backgroundColor: colors.redAccent[700],
            },
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
