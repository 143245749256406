import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignFunctions from "./assignFunctions";
import Header from "../../components/Header";

import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";
import Restricted, { useRestricted } from "../../components/Restricted";
import permission from "../../permission";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

const Role = () => {
  const { clientId, clientName } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const axios = useAxios();
  const isEdit = useRestricted(permission.update.role);

  const [assignFunc, setAssignFunc] = useState({
    clientName: clientName,
    roleId: "",
    roleName: "",
    functions: [],
    open: false,
  });

  const dispatch = useDispatch();

  const columns = [
    {
      field: "roleName",
      headerName: "Role Name *",
      editable: isEdit,
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "note",
      headerName: "Note",
      editable: isEdit,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 0.8,
      editable: false,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 0.5,
      editable: false,
    },
    {
      field: "manage",
      headerName: "Manage",
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Restricted to={permission.create.functionRole}>
            <Button
              variant="outlined"
              startIcon={<AssignmentOutlinedIcon />}
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.greenAccent[500],
                ":hover": {
                  backgroundColor: colors.greenAccent[600],
                },
              }}
              onClick={() => {
                handleAssignFunction(params.row);
              }}
              disabled={params.row.isNew ? true : false}
            >
              Assign Functions
            </Button>
          </Restricted>
        );
      },
    },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Restricted to={permission.delete.role}>
            <IconButton
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[500],
                ":hover": {
                  backgroundColor: colors.redAccent[600],
                },
              }}
              onClick={() => {
                handleDeleteRow(params.row);
              }}
              disabled={params.row.isNew ? true : false}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Restricted>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      let url = endPoint.RolesByClient + `/${clientId}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [clientId, axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteRow = async (row) => {
    let roleId = row.roleId;
    dispatch(
      openDialog({
        title: "Delete Role",
        content: `Are you sure you want to Delete Role : "${row.roleName}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.delete(
              endPoint.RolesByClient + `/${roleId}`
            );
            if (response?.status === 200) deleteRow(roleId);
          } catch (err) {
            console.log(err);
            utils.showMessage(
              `${err.message} : ${err.response?.data}`,
              "error"
            );
          }
        },
      })
    );
  };

  const handleNewRow = () => {
    const roleId = Date.now().toString();
    apiRef.current.updateRows([
      {
        roleId: roleId,
        roleName: "",
        note: "",
        status: false,
        isNew: true,
      },
    ]);

    let sort = apiRef.current
      .getSortedRows()
      .sort((a, b) => a.status - b.status);
    setRowData(sort);

    apiRef.current.startRowEditMode({
      id: roleId,
      fieldToFocus: "roleName",
    });
  };

  const handleProcessRowUpdateError = (error) => {
    utils.showMessage(`Error ${error}`, "error");
  };

  const handleRowEditStop = (params) => {
    let row = params.row;
    if (params.reason === "escapeKeyDown" && row.isNew) {
      deleteRow(row.roleId);
    }
  };

  const handleAssignFunction = async (row) => {
    let params = {
      clientId: clientId,
      roleId: row.roleId,
    };
    try {
      const response = await axios.get(
        endPoint.FunctionsByRole + utils.buildUrlParams(params)
      );

      if (response?.status === 200)
        setAssignFunc((prev) => ({
          ...prev,
          roleId: row.roleId,
          roleName: row.roleName,
          functions: response.data,
          open: true,
        }));
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.roleName.trim().length < 1)
      throw new Error("Role Name Must Have Value !");

    let obj = {
      clientId: clientId,
      roleName: newRow.roleName,
      note: newRow.note,
    };

    let response;
    if (!newRow?.isNew) {
      obj.roleId = newRow.roleId;
      response = await axios.put(endPoint.RolesByClient, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      return newRow;
    } else {
      response = await axios.post(endPoint.RolesByClient, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        deleteRow(newRow.roleId);
        newRow.roleId = response.data.roleId;
        newRow.isNew = false;
        newRow.status = true;
        return newRow;
      }
    }
  };

  const deleteRow = (roleId) => {
    apiRef.current.updateRows([{ roleId: roleId, _action: "delete" }]);
  };

  return (
    <Box m="10px">
      <Header
        title="ROLES"
        subTitle={`Managing Roles For Client : ${clientName}`}
      />
      <Box display="flex">
        <Restricted to={permission.create.role}>
          <Button
            variant="outlined"
            startIcon={<NoteAddOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            onClick={handleNewRow}
          >
            New
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[500],
            ":hover": {
              backgroundColor: colors.redAccent[600],
            },
          }}
          onClick={() => navigate("/client")}
        >
          Back
        </Button>
      </Box>
      <Box
        m="10px 0 0 0 "
        height={"calc(100vh - 220px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.roleId}
          editMode="row"
          autoPageSize
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
      <AssignFunctions data={assignFunc} setData={setAssignFunc} />
    </Box>
  );
};

export default Role;
