import { axiosAuth, endPoint, SYSTEM_ID } from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    setAuth({});
    try {
      let url = endPoint.Auth + "/Logout";
      await axiosAuth.post(url, SYSTEM_ID);
    } catch (err) {
      console.log(err);
    }
  };
  return logout;
};

export default useLogout;
