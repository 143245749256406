import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const SYSTEM_ID = process.env.REACT_APP_SYSTEM;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
  baseURL: AUTH_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const endPoint = {
  SystemInfo: "/SystemInfo",
  ActionInfo: "/ActionInfo",
  FunctionInfo: "/FunctionInfo",
  ActionsByFunction: "/ActionsByFunction",
  ClientInfo: "/ClientInfo",
  SystemsByClient: "/SystemsByClient",
  RolesByClient: "/RolesByClient",
  FunctionsByRole: "/FunctionsByRole",
  TokenSetting: "/TokenSetting",
  SessionInfo: "/SessionInfo",
  AccountInfo: "/AccountInfo",
  Auth: "/Authenticate",
};
