import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard/";
import System from "./pages/system/";
import Client from "./pages/client/";
import Token from "./pages/token/";
import Action from "./pages/action/";
import Function from "./pages/function";
import Role from "./pages/role/index";
import Account from "./pages/account/";
import Session from "./pages/session/";
import Login from "./pages/login/";
import LoginLayout from "./components/LoginLayout";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Forbidden from "./pages/forbidden";
import NotFound from "./pages/notFound";
import ResetPassword from "./pages/resetPassword";
import permission from "./permission";
import Profile from "./pages/profile";

function App() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth permission="" />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route element={<RequireAuth permission={permission.list.system} />}>
            <Route path="system" element={<System />} />
          </Route>
          <Route
            element={<RequireAuth permission={permission.list.function} />}
          >
            <Route
              path="function/:systemId/:systemName"
              element={<Function />}
            />
          </Route>
          <Route element={<RequireAuth permission={permission.list.action} />}>
            <Route path="action/:systemId/:systemName" element={<Action />} />
          </Route>

          <Route element={<RequireAuth permission={permission.list.client} />}>
            <Route path="client" element={<Client />} />
          </Route>
          <Route element={<RequireAuth permission={permission.list.role} />}>
            <Route path="role/:clientId/:clientName" element={<Role />} />
          </Route>
          <Route element={<RequireAuth permission={permission.list.account} />}>
            <Route path="account/:clientId/:clientName" element={<Account />} />
          </Route>

          <Route element={<RequireAuth permission={permission.list.token} />}>
            <Route path="token-session" element={<Token />} />
            <Route path="session" element={<Session />} />
          </Route>
          <Route path="forbidden" element={<Forbidden />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
