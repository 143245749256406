import { useState } from "react";
import {
  Typography,
  useTheme,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Box,
  CardActionArea,
  Collapse,
} from "@mui/material";
import { tokens } from "../../../theme";

const CardItem = ({
  index,
  functionsByRole,
  selected,
  setSelected,
  handleCheck,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        border: `1px solid ${colors.primary[100]}`,
      }}
    >
      <Box
        display="flex"
        sx={{
          borderBottom: `1px solid ${colors.primary[100]}`,
          background: colors.blueAccent[500],
        }}
      >
        <CardActionArea onClick={handleExpandClick}>
          <CardHeader title={functionsByRole.roleName} />
        </CardActionArea>
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: colors.greenAccent[500],
            },
          }}
          id={functionsByRole.roleId}
          checked={functionsByRole.checked}
          onChange={handleCheck}
          value={functionsByRole.roleId}
        />
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {functionsByRole.functionName.map((val, i) => {
            return (
              <Box key={i}>
                <Typography paragraph>{val}</Typography>
              </Box>
            );
          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardItem;
