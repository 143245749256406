import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  TextField,
  IconButton,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { tokens } from "../../theme";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";

const smtpSchema = yup.object().shape({
  smtpHost: yup.string().required("Required"),
  smtpPort: yup
    .number()
    .required("Required")
    .min(0, "Must be greater or equal 0"),
  smtpUsername: yup.string().required("Required"),
  smtpPassword: yup.string().required("Required"),
});

const SmtpSetting = ({ data, setData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxios();
  const initialValues = { ...data.smtpSetting };

  const handleSubmitForm = async (values) => {
    let obj = {
      clientId: data.clientId,
      smtpHost: values.smtpHost,
      smtpPort: values.smtpPort,
      smtpUsername: values.smtpUsername,
      smtpPassword: values.smtpPassword,
    };
    try {
      const response = await axios.put(
        endPoint.ClientInfo + "/SmtpSetting",
        obj,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
        closeDialog();
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const closeDialog = () => {
    setData((prev) => ({
      ...prev,
      open: false,
      clientId: "",
      smtpSetting: {},
    }));
  };

  return (
    <Dialog open={data.open} maxWidth="md" fullWidth>
      <DialogTitle variant="h3">
        Setting SMTP Connection For Client: "{data.clientName}"
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Formik
          enableReinitialize
          onSubmit={handleSubmitForm}
          initialValues={initialValues}
          validationSchema={smtpSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0,1fr))"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="SMTP Host"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.smtpHost}
                  name="smtpHost"
                  error={!!errors.smtpHost}
                  helperText={errors.smtpHost}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="SMTP Port"
                  InputProps={{ inputProps: { min: 0 } }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.smtpPort}
                  name="smtpPort"
                  error={!!errors.smtpPort}
                  helperText={errors.smtpPort}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="SMTP Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.smtpUsername}
                  name="smtpUsername"
                  error={!!errors.smtpUsername}
                  helperText={errors.smtpUsername}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  autoComplete="off"
                  label="SMTP Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.smtpPassword}
                  name="smtpPassword"
                  error={!!errors.smtpPassword}
                  helperText={errors.smtpPassword}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  startIcon={<CheckOutlinedIcon />}
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.greenAccent[600],
                    ":hover": {
                      backgroundColor: colors.greenAccent[700],
                    },
                  }}
                  variant="outlined"
                >
                  Confirm
                </Button>
                <Button
                  onClick={() => closeDialog()}
                  startIcon={<CloseOutlinedIcon />}
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.redAccent[600],
                    ":hover": {
                      backgroundColor: colors.redAccent[700],
                    },
                    marginLeft: "10px",
                  }}
                  variant="outlined"
                >
                  Close
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SmtpSetting;
