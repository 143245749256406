import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Restricted from "../../components/Restricted";
import Header from "../../components/Header";

import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import useAxios from "../../hooks/useAxios";
import utils from "../../utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";
import permission from "../../permission";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

const Session = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const axios = useAxios();

  const columns = [
    {
      field: "clientName",
      headerName: "Client Name",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Login Date",
      flex: 1,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "expiredDate",
      headerName: "Token Expire Date",
      flex: 1,
      valueGetter: (params) => {
        let date = params.row.expiredDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Restricted to={permission.delete.accountSession}>
            <IconButton
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[500],
                ":hover": {
                  backgroundColor: colors.redAccent[600],
                },
              }}
              onClick={() => {
                handleDeleteRow(params.row);
              }}
              disabled={params.row.isNew ? true : false}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Restricted>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      const response = await axios.get(endPoint.SessionInfo, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteRow = async (row) => {
    let params = {
      accountId: row.accountId,
    };
    let url = endPoint.SessionInfo + utils.buildUrlParams(params);
    dispatch(
      openDialog({
        title: "Clear Session Of User",
        content: `Are you sure you want to Clear Session Account: "${row.username}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.delete(url);
            if (response?.status === 200) deleteRow(row.accountId);
          } catch (err) {
            console.log(err);
            utils.showMessage(
              `${err.message} : ${err.response?.data}`,
              "error"
            );
          }
        },
      })
    );
  };

  const deleteRow = (accountId) => {
    apiRef.current.updateRows([{ accountId: accountId, _action: "delete" }]);
  };

  return (
    <Box m="10px">
      <Header title="SESSION" subTitle={"Managing Account Session"} />
      <Box display="flex">
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[500],
            ":hover": {
              backgroundColor: colors.redAccent[600],
            },
          }}
          onClick={() => navigate("/token-session")}
        >
          Back
        </Button>
      </Box>
      <Box
        m="10px 0 0 0 "
        height={"calc(100vh - 220px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.accountId}
          autoPageSize
        />
      </Box>
    </Box>
  );
};

export default Session;
