import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import Restricted from "../../components/Restricted";
import permission from "../../permission";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import useAuth from "../../hooks/useAuth";

const Item = ({ title, to, icon, childRoute }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isActive =
    window.location.pathname === to ||
    childRoute?.some((value) => {
      if (window.location.pathname.includes(value)) return true;
      return false;
    });
  return (
    <MenuItem
      active={isActive}
      style={{
        color: colors.grey[100],
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const navigate = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMINIS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {/* User */}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    backgroundColor: `${colors.primary[400]}`,
                  }}
                  onClick={() => navigate("/profile")}
                />
              </Box>

              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0 " }}
                >
                  {auth.fullName}
                </Typography>
              </Box>
            </Box>
          )}
          {/* Menu Item */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Restricted to={permission.list.system}>
              <Item
                title="Systems"
                to="/system"
                icon={<DisplaySettingsOutlinedIcon />}
                childRoute={["action", "function"]}
              />
            </Restricted>
            <Restricted to={permission.list.client}>
              <Item
                title="Clients"
                to="/client"
                icon={<BusinessOutlinedIcon />}
                childRoute={["role", "account"]}
              />
            </Restricted>
            <Restricted to={permission.list.token}>
              <Item
                title="Sessions & Tokens"
                to="/token-session"
                icon={<PeopleOutlinedIcon />}
                childRoute={["session"]}
              />
            </Restricted>
          </Box>
          <Button
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              margin: "0 auto",
              background: colors.redAccent[500],
              color: colors.grey[100],
              ":hover": {
                backgroundColor: colors.redAccent[600],
              },
            }}
            fullWidth
            startIcon={<ExitToAppOutlinedIcon />}
            onClick={() => handleLogout()}
          >
            {!isCollapsed ? <Typography>Log Out</Typography> : <></>}
          </Button>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
