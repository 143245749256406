import { useState } from "react";
import {
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CardHeader,
  CardContent,
  Box,
  CardActionArea,
  Collapse,
} from "@mui/material";
import { tokens } from "../../../theme";

const CardItem = ({
  index,
  systemFunc,
  selected,
  setSelected,
  handleCheck,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        border: `1px solid ${colors.primary[100]}`,
      }}
    >
      <Box
        display="flex"
        sx={{
          borderBottom: `1px solid ${colors.primary[100]}`,
        }}
      >
        <CardActionArea onClick={handleExpandClick}>
          <CardHeader title={systemFunc.systemName} sx={{background: colors.blueAccent[500]}}/>
        </CardActionArea>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={1}>
            {systemFunc.functionsByRole.map((value, index) => {
              return (
                <Grid item xs={2} sm={3} md={3} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={value.functionId}
                        checked={value.checked}
                        onChange={handleCheck}
                        value={value.functionId}
                        inputProps={{
                          "aria-label": "controlled",
                          "data-systemid": `${systemFunc.systemId}`,
                        }}
                      />
                    }
                    label={`${value.functionName}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardItem;
