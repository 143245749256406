const listAct = {
  system: "ListSystem",
  action: "ListAction",
  function: "ListFunction",
  token: "TokenSettingInfo",
  client: "ListClient",
  role: "ListRolesByClient",
  account: "ListAccountClients",
  accountSession: "ListAccountSession",
};

const createAct = {
  system: "CreateSystem",
  action: "CreateAction",
  function: "CreateFunction",
  actionsFunction: "CreateActionFunction",
  client: "CreateClient",
  systemsClient: "AssignSystemClient",
  role: "CreateRoleByClient",
  functionRole: "CreateFunctionsRole",
  account: "CreateAccount",
  roleAccount: "AssignRoleAccount",
};

const updateAct = {
  system: "UpdateSystem",
  action: "UpdateAction",
  function: "UpdateFunction",
  client: "UpdateClient",
  role: "UpdateRoleByClient",
  defaultRole: "SetDefaultRole",
  account: "UpdateAccount",
  resetPwd: "ResetPassword",
  token: "UpdateTokenSettings",
  jwt: "ResetJwt",
  smtp: "SmtpSetting",
};

const deleteAct = {
  system: "DeleteSystem",
  action: "DeleteAction",
  function: "DeleteFunction",
  client: "DeleteClient",
  role: "DeleteRoleByClient",
  account: "DeleteAccount",
  accountSession: "ClearSession",
};

const permission = {
  list: listAct,
  create: createAct,
  update: updateAct,
  delete: deleteAct,
};

export default permission;
