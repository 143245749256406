import React from "react";
import useAuth from "../hooks/useAuth";
import utils from "../utils";

const Restricted = ({ to, children, fallback }) => {
  const allowed = useRestricted(to);
  return allowed ? <>{children}</> : <>{fallback}</>;
};

export const useRestricted = (action) => {
  const { auth } = useAuth();
  const allowed = utils.checkContains(auth?.actions, action);
  return allowed;
};

export default Restricted;
