import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  useTheme,
  TextField,
  InputAdornment,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CardItem from "./CardItem";
import { tokens } from "../../../theme";
import { useState } from "react";
import { endPoint } from "../../../api/axios";
import useAxios from "../../../hooks/useAxios";
import utils from "../../../utils";

const AssignFunctions = ({ data, setData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["functionName"]);
  const [selected, setSelected] = useState("");
  const axios = useAxios();

  const handleSubmit = async () => {
    let obj = {
      roleId: data.roleId,
      functionId: [],
    };

    let arrObj = [...data.functions];
    for (var i = 0; i < arrObj.length; i++) {
      var func = arrObj[i].functionsByRole
        .filter((func) => {
          return func.checked;
        })
        .map((func) => {
          return func.functionId;
        });
      obj.functionId.push(...func);
    }

    try {
      const response = await axios.post(endPoint.FunctionsByRole, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        utils.showMessage("Assign Systems Succeeded", "success");
        closeDialog();
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  const closeDialog = () => {
    setData((prev) => ({
      ...prev,
      open: false,
      roleId: "",
      roleName: "",
      functions: [],
    }));
    setSelected("");
    setQuery("");
  };

  const handleCheck = (e) => {
    let checked = e.target.checked;
    let functionId = e.target.value;
    let systemId = e.target.getAttribute("data-systemid");
    const systemIndex = data.functions.findIndex(
      (obj) => obj.systemId === systemId
    );
    let newArray = [...data.functions];
    newArray[systemIndex].functionsByRole.map((value) => {
      if (value.functionId === functionId) value.checked = checked;
      return value;
    });
    setData((prev) => ({
      ...prev,
      functions: newArray,
    }));
  };

  return (
    <Dialog open={data.open} maxWidth="lg" fullWidth>
      <DialogTitle variant="h3">
        Assign Functions For Client: "{data.clientName}" - Role: "
        {data.roleName}"
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          "& .MuiCheckbox-root.Mui-checked": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box
          p={1}
          sx={{
            "& label.Mui-focused": {
              color: `${colors.primary[100]}`,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: `${colors.primary[100]}`,
              },
              "&:hover fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
              "&.Mui-focused fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
            },
          }}
        >
          <TextField
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Box>
        <Box>
          {data.functions.map((value, index) => {
            let searchArr = utils.search(
              value.functionsByRole,
              searchParam,
              query
            );
            let isFound = value.functionsByRole.some((item) =>
              searchArr.includes(item)
            );
            return isFound ? (
              <Box key={index} p={0.2}>
                <CardItem
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                  systemFunc={value}
                  handleCheck={handleCheck}
                />
              </Box>
            ) : (
              <Box key={index}></Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          startIcon={<CheckOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.greenAccent[600],
            ":hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
          variant="outlined"
        >
          Confirm
        </Button>
        <Button
          onClick={() => closeDialog()}
          startIcon={<CloseOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[600],
            ":hover": {
              backgroundColor: colors.redAccent[700],
            },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignFunctions;
